import React, { useEffect, useState } from 'react';
import { globalHistory } from '@reach/router';
import classNames from 'classnames';
import stickybits from 'stickybits';
import PropTypes from 'prop-types';

import { useAppContext } from 'src/context/AppContext';

import SiteMetadata from 'src/components/metadata/SiteMetadata';
import Header from 'src/containers/header/Header';
import AuxiliaryNavigation from 'src/containers/navigation/auxiliary/AuxiliaryNavigation';
import PrimaryNavigation from 'src/containers/navigation/primary/PrimaryNavigation';
import Copyright from 'src/components/copyright/Copyright';

import styles from 'src/layouts/app/AppLayout.module.scss';

const AppLayout = ({ children, theme }) => {
  const appContext = useAppContext();
  const isMobile = appContext.breakpoint === 'sm';
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const handleMobileNavOpen = () => {
    setMobileNavOpen(!mobileNavOpen);
  };

  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === 'PUSH') {
          setMobileNavOpen(false);
        }
      }),
    [setMobileNavOpen]
  );

  useEffect(() => {
    stickybits('#navigation');
  });

  return (
    <>
      <SiteMetadata />
      <div className={styles.app}>
        <div
          className={classNames({
            [styles.app__mobileSidebar]: isMobile,
            [styles.app__mobileSidebar_open]: mobileNavOpen,
            [styles.app__desktopSidebar]: !isMobile,
          })}
        >
          <nav id="navigation" className={styles.app__nav}>
            <PrimaryNavigation />
            <AuxiliaryNavigation />
          </nav>
        </div>
        <main
          className={classNames(styles.app__content, {
            [styles[`app__content_${theme}`]]: theme,
            [styles.app__content_mobileNavOpen]: mobileNavOpen,
          })}
        >
          <div
            className={classNames(styles.app__overlay, {
              [styles.app__overlay_open]: mobileNavOpen,
            })}
          />
          <div className={styles.content}>
            <div className={styles.content__wrapper}>
              {isMobile && (
                <header className={styles.content__header}>
                  <Header
                    isDarkTheme={theme === 'dark'}
                    isOpen={mobileNavOpen}
                    handleOpen={() => handleMobileNavOpen()}
                  />
                </header>
              )}
              <div
                className={classNames(styles.content__main, {
                  [styles.content__main_open]: mobileNavOpen,
                })}
              >
                {children}
              </div>
            </div>
          </div>
          <footer
            className={classNames(styles.content__footer, {
              [styles.content__footer_hidden]: mobileNavOpen,
            })}
          >
            <Copyright />
          </footer>
        </main>
      </div>
    </>
  );
};

AppLayout.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string.isRequired,
};

export default AppLayout;
