import React from 'react';
import parse from 'html-react-parser';
import { format } from 'date-fns';

import Heading from 'src/components/content/heading/Heading';

const Copyright = () => (
  <>
    <Heading
      content={`${parse('&copy')} dcumpton.com ${format(new Date(), 'yyyy')}`}
      size={7}
      theme="light"
      weight="thin"
    />
  </>
);

export default Copyright;
