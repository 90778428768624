import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import EmailIcon from 'src/images/navicons/email.svg';
import LinkedInIcon from 'src/images/navicons/linkedin.svg';
import GithubIcon from 'src/images/navicons/github.svg';

import styles from 'src/components/links/auxiliary/AuxiliaryLink.module.scss';

export const setAuxiliaryNavicon = (label) => {
  let component;

  switch (label.toLowerCase()) {
    case 'email':
      component = <EmailIcon className={styles.navicon} />;
      break;
    case 'linkedin':
      component = <LinkedInIcon className={styles.navicon} />;
      break;
    case 'github':
      component = <GithubIcon className={styles.navicon} />;
      break;
    default:
      component = null;
  }

  return component;
};

const AuxiliaryLink = ({ active, label, local, target, url }) => {
  if (local) {
    return (
      <Link
        className={classNames(styles.link, {
          [styles.link_active]: active,
        })}
        to={url}
        target={target}
      >
        {setAuxiliaryNavicon(label)}
      </Link>
    );
  }

  return (
    <a className={styles.link} href={url} target={target}>
      {setAuxiliaryNavicon(label)}
    </a>
  );
};

AuxiliaryLink.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string.isRequired,
  local: PropTypes.bool,
  target: PropTypes.string,
  url: PropTypes.string.isRequired,
};

AuxiliaryLink.defaultProps = {
  active: false,
  local: false,
  target: null,
};

export default AuxiliaryLink;
