import React from 'react';
import PropTypes from 'prop-types';

import MenuIcon from 'src/images/navicons/menu.svg';
import CloseIcon from 'src/images/navicons/close.svg';

import styles from 'src/containers/header/Header.module.scss';

const Header = ({ isDarkTheme, isOpen, handleOpen }) => (
  <div className={styles.header}>
    <button
      className={styles.header__button}
      type="button"
      onClick={() => handleOpen()}
    >
      {isOpen ? (
        <CloseIcon className={styles.header__iconClose} />
      ) : (
        <MenuIcon
          className={`${styles.header__iconOpen} ${
            isDarkTheme
              ? styles.header__iconOpen_light
              : styles.header__iconOpen_dark
          }`}
        />
      )}
    </button>
  </div>
);

Header.propTypes = {
  isDarkTheme: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleOpen: PropTypes.func.isRequired,
};

export default Header;
