import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import PrimaryLink from 'src/components/links/primary/PrimaryLink';

import styles from 'src/containers/navigation/primary/PrimaryNavigation.module.scss';

export const PrimaryNavigationContainer = ({ activeRoute, data }) => (
  <ul className={styles.primary}>
    <li className={styles.primary__item} key={uuidv4()}>
      <PrimaryLink label="home" url="/" active={activeRoute === '/'} />
    </li>
    {data.map((item) => (
      <li className={styles.primary__item} key={uuidv4()}>
        <PrimaryLink
          item={item}
          label={item.label}
          url={item.url}
          active={item.url === activeRoute}
          key={uuidv4()}
        />
      </li>
    ))}
  </ul>
);

PrimaryNavigationContainer.propTypes = {
  activeRoute: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
};

const PrimaryNavigation = () => {
  const data = useStaticQuery(graphql`
    query PrimaryNavigationQuery {
      wpMenu(name: { eq: "Primary" }) {
        menuItems {
          nodes {
            url
            label
          }
        }
      }
    }
  `);
  const location = useLocation();

  return (
    <PrimaryNavigationContainer
      data={data.wpMenu.menuItems.nodes}
      activeRoute={location.pathname}
    />
  );
};

export default PrimaryNavigation;
