import React from 'react';
import { Helmet } from 'react-helmet';

const SiteMetadata = () => (
  <Helmet>
    <link rel="shortcut icon" href="images/dc.ico" sizes="32x32" />
    <title>Doug Cumpton</title>
    <meta
      name="description"
      content="The portfolio of Doug Cumpton, a web developer and content management specialist based in Kansas City, Missouri."
    />
  </Helmet>
);

export default SiteMetadata;
