import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';

import AuxiliaryLink from 'src/components/links/auxiliary/AuxiliaryLink';
import Heading from 'src/components/content/heading/Heading';

import styles from 'src/containers/navigation/auxiliary/AuxiliaryNavigation.module.scss';

export const AuxiliaryNavigationContainer = ({ activeRoute, data }) => (
  <div className={styles.auxiliary}>
    <div className={styles.auxiliary__label}>
      <Heading content="Connect" priority={2} size={7} theme="dark" />
    </div>
    <ul className={styles.auxiliary__items}>
      {data.map((item) => (
        <li className={styles.auxiliary__item} key={uuidv4()}>
          <AuxiliaryLink
            label={item.label}
            local={item.fieldNavigation.local}
            target={item.target}
            url={item.url}
            active={item.fieldNavigation.local && activeRoute === item.url}
          />
        </li>
      ))}
    </ul>
  </div>
);

AuxiliaryNavigationContainer.propTypes = {
  activeRoute: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      target: PropTypes.string,
    }).isRequired
  ).isRequired,
};

const AuxiliaryNavigation = () => {
  const data = useStaticQuery(graphql`
    query AuxiliaryNavigationQuery {
      wpMenu(name: { eq: "Auxiliary" }) {
        menuItems {
          nodes {
            url
            target
            label
            fieldNavigation {
              local
            }
          }
        }
      }
    }
  `);
  const location = useLocation();

  return (
    <AuxiliaryNavigationContainer
      data={data.wpMenu.menuItems.nodes}
      activeRoute={location.pathname}
    />
  );
};

export default AuxiliaryNavigation;
