import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import PropTypes from 'prop-types';

import styles from 'src/components/content/heading/Heading.module.scss';

const Heading = ({
  content,
  element,
  noBreaks,
  priority,
  size,
  theme,
  variant,
  weight,
  wrap,
}) => {
  const classes = classNames(
    styles.heading,
    styles[`heading_s${size}`],
    styles[`heading_${theme}`],
    styles[`heading_${weight}`],
    {
      [styles[`heading_${variant}`]]: variant,
    }
  );

  if (wrap && priority) {
    const Tag = `h${priority}`;
    return <Tag className={classes}>{content}</Tag>;
  }

  const Tag = element;

  if (noBreaks) {
    return (
      <Tag className={classes}>
        {parse(content, {
          // eslint-disable-next-line react/display-name, react/destructuring-assignment
          replace: (domNode) => domNode.name === 'br' && <></>,
        })}
      </Tag>
    );
  }

  return <Tag className={classes}>{parse(content)}</Tag>;
};

Heading.propTypes = {
  content: PropTypes.string.isRequired,
  element: PropTypes.string,
  noBreaks: PropTypes.bool,
  priority: PropTypes.number,
  size: PropTypes.number,
  theme: PropTypes.oneOf(['darkest', 'dark', 'light', 'lightest']),
  variant: PropTypes.oneOf(['resume', 'subheading']),
  weight: PropTypes.oneOf(['bold', 'medium', 'regular', 'thin']),
  wrap: PropTypes.bool,
};

Heading.defaultProps = {
  element: 'div',
  noBreaks: false,
  priority: 1,
  size: 3,
  theme: 'light',
  variant: null,
  weight: 'regular',
  wrap: false,
};

export default Heading;
