import React from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Heading from 'src/components/content/heading/Heading';
import HomeIcon from 'src/images/navicons/home.svg';
import GraphicDesignIcon from 'src/images/navicons/graphic-design.svg';
import PhotographyIcon from 'src/images/navicons/photography.svg';
import ResumeIcon from 'src/images/navicons/resume.svg';
import WorkIcon from 'src/images/navicons/work.svg';

import styles from 'src/components/links/primary/PrimaryLink.module.scss';

export const setPrimaryNavicon = (path) => {
  let component;

  switch (path) {
    case '/':
      component = <HomeIcon className={styles.navicon} />;
      break;
    case '/graphic-design':
      component = <GraphicDesignIcon className={styles.navicon} />;
      break;
    case '/photography':
      component = <PhotographyIcon className={styles.navicon} />;
      break;
    case '/resume':
      component = <ResumeIcon className={styles.navicon} />;
      break;
    case '/work':
      component = <WorkIcon className={styles.navicon} />;
      break;
    default:
      component = null;
  }

  return component;
};

const PrimaryLink = ({ label, url, target, active }) => (
  <Link
    className={classNames(styles.link, {
      [styles.link_active]: active,
    })}
    to={url}
    target={target}
  >
    <div className={styles.link__icon}>{setPrimaryNavicon(url)}</div>
    <Heading content={label} element="span" size={7} theme="dark" />
  </Link>
);

PrimaryLink.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  target: PropTypes.string,
  active: PropTypes.bool.isRequired,
};

PrimaryLink.defaultProps = {
  target: null,
};

export default PrimaryLink;
